var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "库存预警规则",
        visible: _vm.showDialog,
        width: "800px",
        top: "15vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "仓库", prop: "depot_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { clearable: "", placeholder: "仓库" },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.ckList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.depot_name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称", prop: "goods_id" } },
                [
                  _c("goods-search", {
                    ref: "goodsSearch",
                    on: { "select-goods-all": _vm.goodsSelect },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { float: "right" },
                  attrs: { label: "", prop: "" },
                },
                [
                  _c("el-button", { on: { click: _vm.handleAdd } }, [
                    _vm._v("添加规则"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { stripe: "", data: _vm.list },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "title",
                  label: "规则名称",
                  width: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "depot_name",
                  label: "仓库",
                  width: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "goods_count",
                  label: "商品",
                  width: "",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "", label: "操作", width: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleEditRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 修改 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleDelete($index, row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.form.pageNo,
              layout: _vm.layout,
              "page-size": _vm.form.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c("edit", { ref: "edit", on: { refresh: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }