<template>
  <el-dialog
    :modal="false"
    title="库存预警规则"
    :visible.sync="showDialog"
    width="800px"
    top="15vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="90px"
      >
        <el-form-item label="仓库" prop="depot_id">
          <el-select
            v-model="form.depot_id"
            clearable
            style="width: 140px"
            placeholder="仓库"
          >
            <el-option
              v-for="(i, idx) in ckList"
              :key="idx"
              :value="i.id"
              :label="i.depot_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品名称" prop="goods_id">
          <goods-search
            ref="goodsSearch"
            @select-goods-all="goodsSelect"
          ></goods-search>
        </el-form-item>
        <el-form-item label="" prop="" style="float: right">
          <el-button @click="handleAdd">添加规则</el-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="loading" stripe :data="list">
        <el-table-column
          align="center"
          prop="title"
          label="规则名称"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="depot_name"
          label="仓库"
          width=""
        ></el-table-column>
        <el-table-column
          align="center"
          prop="goods_count"
          label="商品"
          width=""
        ></el-table-column>
        <el-table-column align="center" prop="" label="操作" width="">
          <template #default="{ $index, row }">
            <el-button
              type="text"
              @click.native.prevent="handleEditRow($index, row)"
            >
              修改
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="handleDelete($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.pageNo"
        :layout="layout"
        :page-size="form.pageSize"
        :total="total"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
    <edit ref="edit" @refresh="fetchData"></edit>
  </el-dialog>
</template>
<script>
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { depotList } from '@/api/depotManagement'
  import { rulesList, rulesDelete } from '@/api/warning'
  import Edit from './edit'
  export default {
    name: 'Rules',
    components: { GoodsSearch, Edit },
    data() {
      return {
        ckList: [],
        showDialog: false,
        list: [],
        loading: false,
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          pageNo: 1, //
          pageSize: 10, //
          sort: '', //
          order: '', //
          depot_id: '', //仓库ID
          goods_id: '', //商品ID
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        this.fetchData()
        depotList().then((res) => {
          this.ckList = res.data
        })
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, totalCount } = await rulesList(this.form)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      goodsSelect(val) {
        this.form.goods_id = val.goods_id
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleEditRow(index, row) {
        this.$refs.edit.title = '修改规则'
        this.$refs.edit.form.id = row.id
        this.$refs.edit.isAdd = false
        this.$refs.edit.showDialog = true
      },
      handleDelete(index, row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            rulesDelete({ id: row.id }).then((res) => {
              this.$message.success(res.msg)
              this.fetchData()
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleAdd() {
        this.$refs.edit.title = '添加规则'
        this.$refs.edit.isAdd = true
        this.$refs.edit.form.id = ''
        this.$refs.edit.showDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped></style>
