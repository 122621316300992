var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "900px",
        top: "15vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "规则名称:", prop: "title" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预警仓库", prop: "depot_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { clearable: "", placeholder: "预警仓库" },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.ckList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.depot_name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "margin-bottom": "15px", "font-size": "16px" },
                },
                [_vm._v("预警商品:")]
              ),
              _c(
                "el-table",
                {
                  attrs: { stripe: "", data: _vm.form.goods },
                  on: { "selection-change": _vm.selectTable },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", type: "selection", width: "50" },
                  }),
                  _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: "",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                item.label == "商品名称"
                                  ? _c(
                                      "div",
                                      [
                                        _c("goods-search", {
                                          attrs: { "f-key": row.goods_name },
                                          on: {
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.goodsSelect(
                                                $event,
                                                row
                                              )
                                            },
                                            "add-rows": _vm.addRows,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "单位"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "单位",
                                            },
                                            model: {
                                              value: row.unit_type,
                                              callback: function ($$v) {
                                                _vm.$set(row, "unit_type", $$v)
                                              },
                                              expression: "row.unit_type",
                                            },
                                          },
                                          _vm._l(
                                            row.arr_unit,
                                            function (i, idx) {
                                              return _c("el-option", {
                                                key: idx,
                                                attrs: {
                                                  value: i.unit_type,
                                                  label: i.unit_name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : item.label == "缺货预警值"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "number",
                                            min: 0,
                                            onkeyup:
                                              "value=value.replace(/^0(0+)|[^\\d]+/g,'')",
                                          },
                                          model: {
                                            value: row.scarce_value,
                                            callback: function ($$v) {
                                              _vm.$set(row, "scarce_value", $$v)
                                            },
                                            expression: "row.scarce_value",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "积压预警值"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "number",
                                            min: 0,
                                            onkeyup:
                                              "value=value.replace(/^0(0+)|[^\\d]+/g,'')",
                                          },
                                          model: {
                                            value: row.overstock_value,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row,
                                                "overstock_value",
                                                $$v
                                              )
                                            },
                                            expression: "row.overstock_value",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      align: "center",
                      label: "操作",
                      "min-width": "110px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleDeleteRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form2",
              attrs: {
                model: _vm.form2,
                inline: "",
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { margin: "15px 0", "font-size": "16px" } },
                [_vm._v("批量设置:")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预警单位:", prop: "unit_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { clearable: "", placeholder: "预警单位" },
                      model: {
                        value: _vm.form2.unit_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form2, "unit_type", $$v)
                        },
                        expression: "form2.unit_type",
                      },
                    },
                    _vm._l(_vm.unit_type, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "缺货预警值:", prop: "a" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form2.a,
                      callback: function ($$v) {
                        _vm.$set(_vm.form2, "a", $$v)
                      },
                      expression: "form2.a",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "积压预警值:", prop: "b" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form2.b,
                      callback: function ($$v) {
                        _vm.$set(_vm.form2, "b", $$v)
                      },
                      expression: "form2.b",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.setBatch } },
                    [_vm._v("设置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form3",
              attrs: {
                model: _vm.form3,
                inline: "",
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { margin: "15px 0", "font-size": "16px" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.checked,
                        callback: function ($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked",
                      },
                    },
                    [_vm._v(" 统一设置:将该仓库全部商品设置为 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预警单位:", prop: "unit_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { clearable: "", placeholder: "预警单位" },
                      model: {
                        value: _vm.form3.unit_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form3, "unit_type", $$v)
                        },
                        expression: "form3.unit_type",
                      },
                    },
                    _vm._l(_vm.unit_type, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "缺货预警值:", prop: "a" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form3.a,
                      callback: function ($$v) {
                        _vm.$set(_vm.form3, "a", $$v)
                      },
                      expression: "form3.a",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "积压预警值:", prop: "b" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form3.b,
                      callback: function ($$v) {
                        _vm.$set(_vm.form3, "b", $$v)
                      },
                      expression: "form3.b",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保 存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }